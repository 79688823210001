<template>
  <div class="content-box">
    <el-form ref="loginForm" class="login-form" :model="loginForm" :rules="loginFormRules">
      <div class="title">捷思联想检验智能管控系统</div>
      <el-form-item prop="userName">
        <el-input prefix-icon="el-icon-user" v-model="loginForm.userName" placeholder="请输入用户名" @keyup.enter.native="login"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input prefix-icon="el-icon-key" v-model="loginForm.password" placeholder="请输入密码" show-password @keyup.enter.native="login"></el-input>
      </el-form-item>
      <el-form-item prop="verifyCode" v-if="enableVerifyCode">
        <el-col :span="17">
          <el-input prefix-icon="el-icon-key" v-model="loginForm.verifyCode" placeholder="请输入右图中的验证码" @keyup.enter.native="login"></el-input>
        </el-col>
        <el-col class="code-box" :span="7">
          <img class="code-img" :src="verifyImgSrc" @click="createCode">
        </el-col>
      </el-form-item>
      <el-form-item prop="currentPosition">
        <el-input prefix-icon="el-icon-map-location" v-model="loginForm.currentPosition" placeholder="请扫工位条码" @keyup.enter.native="login"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="login-btn" :loading="loading" type="primary" @click="login">
          <span v-if="!loading">登录</span>
          <span v-if="loading">登录中...</span>
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    let posRule = /^OP[0-9]{3}$/;
    let checkPosition = (rule, value, callback) => {
      if (!posRule.test(value)) {
        return callback(new Error('工位不正确'))
      } else {
        callback()
      }
    };

    return {
      loginForm: {
        userName: '',
        password: '',
        verifyCode: '',
        verifyId: '',
        currentPosition: ''
      },
      loading: false,
      enableVerifyCode: false,
      verifyImgSrc: '',
      loginFormRules: {
        userName: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        verifyCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ],
        currentPosition: [
          {required: true, message: '请扫工位条码', trigger: 'blur'},
          {validator: checkPosition}
        ]
      }
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate(async valid => {
        if (!valid) {
          return
        }

        this.loading = true;

        try {
          let {data: res} = await this.$axios.post('login', this.loginForm);
          this.loading = false;
          if (res.code !== 0) {
            this.$message.error(res.message);
            await this.createCode();
            return
          }

          localStorage.setItem('current_position', this.loginForm.currentPosition)
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('tokenStatus', 'true')
          this.$router.push('/home?v=' + new Date().getTime())
          this.$message({
            type: 'success',
            message: '你的工位是: ' + localStorage.getItem('current_position')
          });
        } catch (e) {
          console.error(e)
          this.loading = false;
          this.createCode();
          this.$message.error('网络连接失败')
        }

      })
    },
    async createCode() {
      let {data: res} = await this.$axios.get('getVerifyCode');
      if (res.code !== 0) {
        this.$message.error('验证码获取失败')
        return;
      }
      if ('false' === res.data) {
        this.enableVerifyCode = false;
        return;
      } else {
        this.enableVerifyCode = true;
      }
      this.loginForm.verifyId = res.data.id;
      this.verifyImgSrc = 'data:image/jpeg;base64,' + res.data.imgBase64;
    }
  },
  created() {
    this.createCode();
  }
}
</script>

<style scoped>
.content-box {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("../assets/images/login/bg.png") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  width: 440px;
  height: 340px;
  background-color: #fff;
  padding: 20px;
  border: 2px solid darkgrey;
  box-shadow: 5px 5px 8px lightgray;
  border-radius: 5px;
}

.title {
  font-size: 22px;
  color: darkslategray;
  text-align: center;
  font-weight: bold;
  padding-bottom: 20px;
}

.code-box {
  text-align: right;
}

.code-img {
  width: 100px;
  display: block;
  height: 40px;
  float: right;
  cursor: pointer;
}

.login-btn {
  width: 100%;
}
</style>
